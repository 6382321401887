.warning-text-box {
    display: inline-flex; /* Align icon and text horizontally */
    align-items: center; /* Vertically center align items */
    background-color: pink; /* Light orange background to indicate a warning */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 10px; /* Padding inside the box */
    gap: 8px; /* Space between icon and text */
    border: 1px solid red; /* Orange border to match the warning theme */

    &__icon {
        width: 1em; /* Size matches the text size */
        height: 1em; /* Ensure square dimensions */
        background-color: white ; /* Warning yellow/orange color */
        border-radius: 50%; /* Circular icon */
        display: inline-block; /* Inline block to style as a standalone element */
      }
      
    &__text {
        font-size: inherit; /* Match font size with parent */
        color: #333; /* Dark text color for readability */
        color: $c-dark-grey;
        font-size: $text-size-xs;
        font-weight: $text-weight-regular;  
    }
  }
  

  