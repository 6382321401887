.page-summary {
	width: 100%;

	.summary {
		padding: 10px 15px;

		&__form {
			width: 100%;
			max-width: 1024px;
			margin: auto;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__title {
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__time-counter {
			color: $c-black;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
		}

		&__container {
			width: 100%;
			gap: 20px;
			border-radius: 8px;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end; /* Align items to the right */  

		}

		&__wrapper {
			width: 100%;
			max-width: 330px;
			gap: 20px;
			display: flex;
			flex-direction: column;

			&:last-child {
				max-width: calc(100% - 350px);
			}

			@include max-width($scr-sm) {
				max-width: 100% !important;
			}
		}

		&__button-container {
			padding: 20px;
			display: flex;
			justify-content: flex-end;

			.app-button {
				//
				&--outline {
					//
					.app-button {
						//
						&__label {
							color: $c-teal;
						}
					}
				}

				&__label {
					font-size: $text-size-xs;
				}

				&:disabled {
					&:first-child {
						border: 0;
						background-color: transparent;

						.app-button__label {
							color: #828a8f;
						}
					}
				}
			}
		}

		&__tnc-required {
			color: $c-red;
		}

		&__text {
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
			text-align: justify;
		}

		.total-quotation {
			width: 100%;

			&__container {
				border-radius: 8px;
				background-color: $c-white;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			}

			&__wrapper {
				display: flex;
				flex-direction: column;
			}

			&__form {
				padding: 0 20px;
				
			}

			&__title {
				margin: 0;
				padding: 20px;
				color: $c-white;
				font-size: $text-size-body;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				background-color: $c-turquoise;
				font-weight: $text-weight-semibold;
			}

			&__divider {
				border: 1px dashed $c-disabled;
			}

			&__info-text {
				margin: 20px 0;
				color: $c-light-grey;
				font-size: $text-size-xs;
				
			}
		}

		.quotation-list {
			margin: 0;
			padding: 0;
			list-style-type: none;

			&__item {
				margin: 15px 0;
				display: flex;
				justify-content: space-between;
			}

			&__total {
				margin: 15px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			&__border
			{
				border: 1px dashed #d3e0e8;	
			}

			&__text {
				margin: 0;
				text-align: left;
				color: $c-black;
				word-wrap: break-word;
				font-size: $text-size-s;
				flex: 1;

				&--value {
					color: $c-black;
					text-align: right;
				}
			}

			&__description {
				margin: 0;
				color: $c-black;
				text-align: left;
				word-wrap: break-word;
				font-size: $text-size-s;
				font-weight: $text-weight-bold;
				flex: 1;

				&--value {
					color: $c-black;
					text-align: right;
				}
			}

			&__sub-description {
				margin: 0;
				color: $c-black;
				text-align: left;
				word-wrap: break-word;
				font-size: $text-size-m;
				font-weight: $text-weight-bold;
				flex: 1;

				&--value {
					color: $c-black;
					text-align: right;
				}
			}
		}

		.referral {
			//
			&__container {
				border-radius: 8px;
				background-color: $c-white;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			}

			&__box {
				padding: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__wrapper {
				gap: 16px;
				display: flex;
				align-items: center;
			}

			&__logo {
				width: 32px;
				height: 32px;
			}

			&__label {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}

			&__value {
				//
				.MuiInputBase-input {
					padding: 0;

					&::placeholder {
						font-size: $text-size-s;
					}
				}

				.MuiOutlinedInput-notchedOutline {
					border: 0 !important;
				}
			}
		}
	}
}
