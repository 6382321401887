.page-payment {
	width: 100%;

	.payment {
		padding: 10px 15px;
		
		&__title {
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__form {
			width: 100%;
			max-width: 1024px;
			margin: auto;
		}

		&__container {
			width: 100%;
			border-radius: 8px;
			gap: 20px;
			display: flex;
			flex-wrap: wrap;
		}

		&__wrapper {
			width: 100%;
			gap: 20px;
			display: flex;
			flex-direction: column;

			@include max-width($scr-sm) {
				max-width: 100% !important;
			}
		}

		&__box {
			border-radius: 8px;
			background-color: $c-white;
		}

		&__box-body {
			width: 100%;
			padding: 0;
			gap: 20px 35px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			padding-bottom: 10px;

			.app-input,
			.app-mobile-input,
			.app-select-input,
			.app-button-input,
			.app-masking-input,
			.app-calendar-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}	
			}

			&-text {
				font-size: 14px;
				padding-top: 15px;       
				padding-bottom: 0px;
				font-weight: 500; /* Specify the desired weight */
				color: darkblue;


				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}

				&-description{
					font-size: $text-size-xs;	
				}
			}

		}

		&__button-container {
			padding: 20px;
			display: flex;
			justify-content: flex-end;

			.app-button {
				//
				&--outline {
					//
					.app-button {
						//
						&__label {
							color: $c-teal;
						}
					}
				}

				&__label {
					font-size: $text-size-xs;
				}

				&:disabled {
					&:first-child {
						border: 0;
						background-color: transparent;

						.app-button__label {
							color: #828a8f;
						}
					}
				}
			}
		}
	}

}
