.app-card {
	width:100%;
	min-width: 250px;
	margin-bottom: 15px;

	@include max-width($scr-md) {
		width: 100%;
	}

	.main-content {
		background-color: transparent;
		display: flex;

		&__radio {
			width: 20px;
			height: 20px;
			padding: 3px;
			margin-bottom: auto;
			border-radius: 100%;
			background-color: transparent;
			border: 1px solid $c-light-grey;
			cursor: pointer;

			&--active {
				//
				&:before {
					width: 100%;
					height: 100%;
					content: "";
					background-color: $c-teal;
					border-radius: 100%;
					display: block;
				}
			}
		}

		&__container {
			width: 100%;
			min-width: 0;
			padding: 10px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		}

		&__box {
			padding: 10px;
		}

		&__divider {
			width: 100%;
			height: 1px;
			background-color: $c-disabled;
		}

		&__text {
			color: $c-dark-grey;
			font-size: $text-size-xxs;
			font-weight: $text-weight-regular;
		}

		&__title {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__body {
			text-align: right;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;

		&__company {
			padding: 0 5px 0 0;
			display: flex;
			align-items: center;

			&--right{
				text-align: right;
			}
		}

		&__image {
			width: 30px;
			height: 30px;
			border-radius: 70px;
			margin: 0 5px 0 0;
			border: 4px solid $c-white;
			box-shadow: 2px 4px 8px 0px rgba(37, 37, 37, 0.08);
			flex-shrink: 0;

			img {
				width: 100%;
			}
		}

		&__text {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;

			@include max-width($scr-xs) {
				font-size: $text-size-xs;
			}
		}

		&__title {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			//word-break: break-word;

			&--content{
				color: $c-light-grey;
			}

			@include max-width($scr-xs) {
				font-size: $text-size-xs;
			}
		}

		&__body {
			text-align: right;
			display: flex;
			flex-direction: column;
		}

		&__content{
			padding-top: 10px;
			display: flex;
			justify-content: space-between;
			width: 100%;

			&--title{
				flex-direction: column; /* Stack titles vertically */
				margin: 0;
			}
		}

		&__wrapper{
			text-align: center;
		}
	}

	.body {
		margin: 5px;

		&__content {
			display: flex;
			justify-content: space-between;
		}

		&__content-row{
			width: 100%;
		}

		&__content-item{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			padding-bottom: 10px;
			justify-content: space-between;
		}

		&__content-icon{
			width: 30px;
			height: 30px;
			
			border-radius: 70px;
			margin: 0 5px 0 0;
			border: 4px solid $c-white;
			box-shadow: 2px 4px 8px 0px rgba(37, 37, 37, 0.08);
			flex-shrink: 0;
		}

		&__text {
			margin: 0;
			color: $c-dark-grey;
			word-break: break-all;
			font-size: $text-size-xs;
		}

		&__title {
			text-align: right;
			color: $c-dark-grey;
			word-break: break-all;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__wrapper {
			justify-content: center;
			padding: 2px 0px;
			width: 100%;
			margin: 5px;

			width: calc(50% - 20px);
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center; 
		}

		&__sum-insured {
			text-align: right;
			flex: 1;
		}
	}

	.footer {
		padding: 15px 0 0;
		display: flex;
		justify-content: center;

		.app-button {
			padding: .5rem .5rem;
			max-width: 100%;
			background-color: $c-teal;
			margin: 0 auto;

			&__label {
				color: $c-white;
				font-size: $text-size-s;
			}
		}
	}
}

.app-detail-menu {
	width: 100%;

	.MuiList-root {
		padding: 16px 0 16px 16px;

		@include max-width($scr-md) {
			padding: 16px;
		}
	}

	.MuiBackdrop-root {
		pointer-events: auto;
	}

	.MuiPaper-root {
		width: 100%;
		max-width: 1024px;
		padding-right: 16px;
		box-shadow: none;
		background-color: transparent;

		@include max-width($scr-md) {
			left: 0 !important;
		}
	}

	.detail-menu {
		width: 100%;
		min-width: 300px;
		max-width: calc(100% - 350px);
		margin-left: auto;
		border-radius: 10px;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		background-color: $c-white;
		pointer-events: auto;
		display: flex;
		flex-direction: column;

		@include max-width($scr-md) {
			max-width: 100%;
			margin-left: 0;
		}

		&__container {
			width: 100%;
			min-width: 0;
			padding: 20px;
		}

		.header {
			margin: 20px 0;
			display: flex;
			justify-content: space-between;

			&__company {
				padding: 0 5px 0 0;
				display: flex;
				align-items: center;
			}

			&__image {
				width: 30px;
				height: 30px;
				border-radius: 70px;
				margin: 0 5px 0 0;
				border: 4px solid $c-white;
				box-shadow: 2px 4px 8px 0px rgba(37, 37, 37, 0.08);
				flex-shrink: 0;

				img {
					width: 100%;
				}
			}

			&__text {
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-regular;
			}

			&__title {
				margin: 0;
				color: $c-black;
				font-size: $text-size-body;
				font-weight: $text-weight-semibold;
			}
		}

		.body {
			padding: 10px 0;

			&__title {
				color: $c-dark-grey;
				word-break: break-word;
				font-size: $text-size-s;
				font-weight: $text-weight-regular;
				font-style: italic;
			}

			&__list {
				list-style-type: none;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 1rem;
				max-width: 100%;
			}

			&__item {
				display: flex; 
				align-items: center; 
			}

			&__image{
				width: 30px;
				height: 30px;
				border-radius: 70px;
				margin: 0 5px 0 0;
				border: 4px solid $c-white;
				box-shadow: 2px 4px 8px 0px rgba(37, 37, 37, 0.08);
				flex-shrink: 0;

				img {
					width: 100%;
				}
			}

			&__text {
				margin: 0;
				color: $c-dark-grey;
				word-break: break-word;
				font-size: $text-size-s;
			}
		}

		.footer {
			padding-top: 20px;
			display: flex;
			justify-content: space-between;

			@include max-width($scr-xs) {
				flex-direction: column;
				gap: 10px;
			}

			&__content {
				gap: 20px;
				display: flex;
				justify-content: space-between;
			}

			&__upper-content {
				gap: 20px;
				display: flex;
			}

			&__sum-content {
				display: flex;
			}

			&__text {
				color: $c-dark-grey;
				word-break: break-all;
				font-size: $text-size-s;

				@include max-width($scr-xs) {
					font-size: $text-size-xs;
				}
			}

			&__title {
				margin-top: auto;
				color: $c-dark-grey;
				word-break: break-all;
				font-size: $text-size-s;
				font-weight: $text-weight-normal;

				@include max-width($scr-xs) {
					font-size: $text-size-xs;
				}
			}

			&__sub-title {
				text-align: right;
				color: $c-dark-grey;
				word-break: break-all;
				font-size: $text-size-body;
				font-weight: $text-weight-semibold;

				@include max-width($scr-xs) {
					font-size: $text-size-s;
				}
			}

			&__wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			&__premium-amount {
				text-align: right;
			}
		}

		&__button-container {
			background-color: $c-teal;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;

			.app-button {
				margin: auto;
			}
		}

		&__divider {
			width: 100%;
			height: 1px;
			background-color: $c-disabled;
		}

		&__remove {
			border: 0;
			background-color: transparent;
			cursor: pointer;
			pointer-events: auto;
			flex-shrink: 0;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
}

.download-menu {
	//
	&__text {
		min-width: 100px;
		text-decoration: none;
		font-size: $text-size-s;
		font-weight: $text-weight-regular;
	}
}

.benefits-table-wrapper {
    overflow-x: auto;
    margin: 20px 0;
}

.benefits-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
}

.benefits-table th,
.benefits-table td {
    padding: .3rem;
    border: 1px solid #e0e0e0;
    text-align: left;
	font-size: $text-size-xs;
}

.benefits-table th {
    background: #f8f9fa;
    font-weight: $text-weight-semibold;
    text-align: center;
}


.benefit-info {
    display: flex;
    align-items: center;
    gap: 10px;

	&_name {
		font-size: $text-size-xs;
	}
}

.benefit-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.coverage-amount {
    text-align: right;
    font-weight: 500;
}
