.page-trip-type {
	height: 100%;

	.enquiry {
		height: 100%;

		&__container {
			width: 100%;
			height: 100%;
			max-width: 1024px;
			margin: 0 auto;
			padding: 40px 20px;

			@include max-width($scr-xs) {
				padding: 15px;
			}
		}

		&__form {
			height: 100%;
		}

		&__box {
			height: 100%;
			border-radius: 8px;
			background-color: $c-white;
		}

		&__wrapper {
			max-width: 640px;
			margin: auto;
			padding: 40px;

			@include max-width($scr-xs) {
				padding: 25px 15px;
			}
		}

		&__title {
			margin: 0;
			color: $c-teal;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-body;
		}

		&__box-body {
			padding: 20px 0 0 0;
			gap: 20px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			&__individual
			{
				gap: 20px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
			}
			&__company
			{
				gap: 20px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
			}
		}

		&__tnc-pdf {
			color: $c-blue;
			font-size: $text-size-s;
			text-decoration: none;
		}

		&__tnc-required {
			color: $c-red;
		}

		&__button-container {
			width: 100%;
			padding: 20px 0;

			&--declaration{
				padding-top: 20px;
			}
		}
	}
}
