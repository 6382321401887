.page-applicant {
	width: 100%;

	.card-sum-insured {
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		background-color: $c-white;

		&__header {
			margin: 0;
			padding: 20px;
			color: $c-white;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			background-color: $c-turquoise;
			border-radius: 8px 8px 0 0;
		}

		&__container {
			margin: auto;
			padding: 20px;

			.app-input__label {
				font-size: $text-size-xs;
			}

			.MuiInputBase-input {
				color: $c-black;
				font-size: $text-size-s;
				font-weight: $text-weight-regular;
			}
		}

		&__form {
			gap: 20px;
			display: flex;
			flex-direction: column;
		}

		&__wrapper {
			margin: 0 0 20px 0;
			display: flex;
			justify-content: space-between;
		}

		&__box {
			display: flex;
			justify-content: space-between;
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__button-wrapper {
			cursor: pointer;
			display: flex;

			.app-button {
				max-width: 168px;
				border: 0;

				&--outline {
					padding: 0 5px 0 0;
				}

				&__label {
					color: $c-dark-grey;
					font-size: $text-size-xs;
				}
			}
		}

		&__header-wrapper {
			display: flex;
		}

		&__text {
			color: $c-dark-grey;
			font-size: $text-size-s;

			&--active {
				color: $c-black;
				text-align: right;
			}
		}

		&__detail{
			display: flex;
			justify-content: space-between;
			padding-bottom: 10px; /* Adds space above */
		}

		&__detail-left{
			color: $c-dark-grey;
			text-align: left;
			font-size: $text-size-s;
		}

		&__detail-right{
			text-align: right;
			font-size: $text-size-s;
		}
	}

	.applicant {
		padding: 10px 15px;
	
		&__title {
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__form {
			width: 100%;
			max-width: 1024px;
			margin: auto;
		}

		&__container {
			width: 100%;
			border-radius: 8px;
			gap: 20px;
			display: flex;
			flex-wrap: wrap;
		}

		&__wrapper {
			width: 100%;
			max-width: 330px;
			gap: 20px;
			display: flex;
			flex-direction: column;

			&:last-child {
				max-width: calc(100% - 350px);
			}

			@include max-width($scr-sm) {
				max-width: 100% !important;
			}
		}

		&__box {
			border-radius: 8px;
			background-color: $c-white;
		}

		&__box-body {
			width: 100%;
			padding: 0;
			gap: 20px 35px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			padding-bottom: 10px;

			.app-input,
			.app-mobile-input,
			.app-select-input,
			.app-button-input,
			.app-masking-input,
			.app-calendar-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}

			&-text {
				font-size: 14px;
				padding-top: 15px;       
				padding-bottom: 0px;
				font-weight: 500; /* Specify the desired weight */

				@include min-width($scr-sm) {
					width: calc(100% - 17.5px);
				}
			}
		}

		&__button-container {
			width: 100%;
			padding: 20px;
			display: flex;
			justify-content: flex-end;

			.app-button {
				//
				&--outline {
					//
					.app-button {
						//
						&__label {
							color: $c-teal;
						}
					}
				}

				&:disabled {
					&:first-child {
						border: 0;
						background-color: transparent;

						.app-button__label {
							color: #828a8f;
						}
					}
				}
			}
		}

		&__container-body {
			height: 100%;
			border-radius: 8px;
			background-color: $c-white;
		}

		&__container-box {
			height: 100%;
			width: 100%;
			max-width: 1024px;
			margin: 0 auto;
			padding: 40px 20px;

			@include max-width($scr-xs) {
				padding: 15px;
			}
		}
	}
}
